<template>
    <div>
        <strong>Términos y Condiciones</strong> <p>
            Al descargar o usar la aplicación, estos términos se aplicarán automáticamente a usted; por lo tanto, asegúrese de leerlos cuidadosamente antes de usar la aplicación. No está permitido copiar o modificar la aplicación, ninguna parte de la aplicación, ni nuestras marcas comerciales de ninguna manera. No tiene permitido intentar extraer el código fuente de la aplicación, y tampoco debe intentar traducir la aplicación a otros idiomas o crear versiones derivadas. La propia aplicación, y todas las marcas comerciales, derechos de autor, derechos de base de datos y otros derechos de propiedad intelectual relacionados con ella, siguen perteneciendo a Quiz & Jogos Online.
        </p> <p>
            Quiz & Jogos Online se compromete a garantizar que la aplicación sea lo más útil y eficiente posible. Por esa razón, nos reservamos el derecho de hacer cambios en la aplicación o cobrar por sus servicios, en cualquier momento y por cualquier motivo. Nunca le cobraremos por la aplicación o sus servicios sin dejar muy claro exactamente por qué está pagando.
        </p> <p>
            La aplicación Diario Emocional almacena y procesa datos personales que nos ha proporcionado, para proporcionar mi Servicio. Es su responsabilidad mantener seguro su teléfono y el acceso a la aplicación. Por lo tanto, recomendamos que no haga jailbreak o root en su teléfono, que es el proceso de eliminación de restricciones y limitaciones de software impuestas por el sistema operativo oficial de su dispositivo. Esto podría hacer que su teléfono sea vulnerable a malware/virus/programas maliciosos, comprometer las características de seguridad de su teléfono y podría significar que la aplicación Diario Emocional no funcione correctamente o en absoluto.
        </p> <div><p>
            La aplicación utiliza servicios de terceros que declaran sus Términos y Condiciones.
            </p> <p>
            Enlace a los Términos y Condiciones de los proveedores de servicios de terceros utilizados por la aplicación
            </p> <ul><li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Servicios de Google Play</a></li><li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google Analytics para Firebase</a></li><li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/tos" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
            Debe saber que hay ciertas cosas por las que Quiz & Jogos Online no asumirá responsabilidad. Ciertas funciones de la aplicación requerirán que la aplicación tenga una conexión a internet activa. La conexión puede ser Wi-Fi o proporcionada por su proveedor de red móvil, pero Quiz & Jogos Online no puede asumir responsabilidad si la aplicación no funciona con funcionalidad completa si no tiene acceso a Wi-Fi y no le quedan datos de conexión.
        </p> <p></p> <p>
            Si está utilizando la aplicación fuera de un área con Wi-Fi, debe recordar que los términos del acuerdo con su proveedor de red móvil aún se aplicarán. Como resultado, es posible que su proveedor móvil le cobre por el costo de los datos durante la conexión al acceder a la aplicación, u otras tarifas de terceros. Al usar la aplicación, acepta la responsabilidad de tales cargos, incluidos los cargos de datos de roaming si utiliza la aplicación fuera de su territorio doméstico (es decir, región o país) sin desactivar el roaming de datos. Si no es el pagador de la factura del dispositivo en el que está utilizando la aplicación, tenga en cuenta que asumimos que ha recibido permiso del pagador de la factura para utilizar la aplicación.
        </p> <p>
            Del mismo modo, Quiz & Jogos Online no siempre puede asumir responsabilidad por la forma en que utiliza la aplicación, es decir, debe asegurarse de que su dispositivo esté cargado: si se queda sin batería y no puede encenderlo para aprovechar el Servicio, Quiz & Jogos Online no puede aceptar responsabilidad.
        </p> <p>
            Con respecto a la responsabilidad de Quiz & Jogos Online por su uso de la aplicación, cuando esté utilizando la aplicación, es importante tener en cuenta que, aunque nos esforzamos por garantizar que esté actualizada y correcta en todo momento, dependemos de terceros para proporcionarnos información para que podamos ponerla a su disposición. Quiz & Jogos Online no acepta ninguna responsabilidad por cualquier pérdida, directa o indirecta, que pueda experimentar como resultado de confiar exclusivamente en esta funcionalidad de la aplicación.
        </p> <p>
            En algún momento, es posible que deseemos actualizar la aplicación. La aplicación está actualmente disponible en Android: los requisitos para el sistema (y para cualquier sistema adicional que decidamos extender la disponibilidad de la aplicación) pueden cambiar, y deberá descargar las actualizaciones si desea seguir utilizando la aplicación. Quiz & Jogos Online no promete que siempre actualizará la aplicación para que sea relevante para usted y/o funcione con la versión de Android que tenga instalada en su dispositivo. Sin embargo, se compromete a aceptar siempre las actualizaciones de la aplicación cuando se le ofrezcan. También podemos desear dejar de proporcionar la aplicación, y podemos terminar el uso de la misma en cualquier momento sin previo aviso para usted. A menos que le digamos lo contrario, tras cualquier terminación, (a) los derechos y licencias otorgados a usted en estos términos terminarán; (b) debe dejar de utilizar la aplicación y, si es necesario, eliminarla de su dispositivo.
        </p> <p><strong>Cambios a Estos Términos y Condiciones</strong></p> <p>
            Puedo actualizar nuestros Términos y Condiciones de vez en cuando. Por lo tanto, se le aconseja que revise esta página periódicamente para ver cualquier cambio. Le notificaré cualquier cambio publicando los nuevos Términos y Condiciones en esta página.
        </p> <p>
            Estos términos y condiciones son efectivos a partir del 2024-02-22
        </p> <p><strong>Contáctenos</strong></p> <p>
            Si tiene alguna pregunta o sugerencia sobre mis Términos y Condiciones, no dude en contactarme en quizejogosonline@gmail.com.
        </p>
    </div>
</template>

<script>
export default {
    name: "DiarioEmocionalTermsAndContions"
}
</script>