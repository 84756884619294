<template>
    <div>
        <strong>Privacybeleid</strong> <p>
        Quiz & Jogos Online heeft de Emotioneel Eagboek app gebouwd als
        een app met advertenties. DIT IS EEN DIENST die wordt geleverd door
        Quiz & Jogos Online gratis en is bedoeld voor gebruik zoals het is.
    </p> <p>
        Deze pagina wordt gebruikt om bezoekers te informeren over mijn
        beleid met betrekking tot het verzamelen, gebruiken en bekendmaken van persoonlijke
        informatie als iemand besluit mijn dienst te gebruiken.
    </p> <p>
        Als u ervoor kiest om mijn dienst te gebruiken, stemt u in met de
        verzameling en het gebruik van informatie in verband met dit
        beleid. De persoonlijke informatie die ik verzamel, wordt gebruikt om
        de dienst te leveren en te verbeteren. Ik zal uw informatie niet gebruiken of delen met
        iemand anders zoals beschreven in dit privacybeleid.
    </p> <p>
        De termen die in dit privacybeleid worden gebruikt, hebben dezelfde betekenis
        als in onze algemene voorwaarden, die toegankelijk zijn op
        Emotioneel Eagboek, tenzij anders omschreven in dit privacybeleid.
    </p> <p><strong>Informatieverzameling en -gebruik</strong></p> <p>
        Voor een betere ervaring bij het gebruik van onze dienst, kan ik u vragen om ons bepaalde persoonlijke
        identificeerbare informatie te verstrekken, waaronder maar niet beperkt tot naam, e-mail. De informatie die
        ik vraag zal worden bewaard op uw apparaat en zal op geen enkele manier door mij worden verzameld.
    </p> <div><p>
        De app maakt gebruik van diensten van derden die mogelijk
        informatie verzamelen die wordt gebruikt om u te identificeren.
        </p> <p>
        Link naar het privacybeleid van de serviceproviders van derden die worden gebruikt
        door de app
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play-services</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=nl" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics voor Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/privacy_policy" target="_blank" rel="noopener noreferrer">OneSignal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Loggegevens</strong></p> <p>
        Ik wil u informeren dat telkens wanneer u
        mijn dienst gebruikt, in geval van een fout in de app
        ik gegevens en informatie verzamel (via producten van derden)
        op uw telefoon genaamd Loggegevens. Deze Loggegevens kunnen
        informatie bevatten zoals het IP-adres van uw apparaat, de naam van het apparaat, de versie van het besturingssysteem, de
        configuratie van de app bij het gebruik van mijn dienst,
        de tijd en datum van uw gebruik van de dienst en andere
        statistieken.
    </p> <p><strong>Cookies</strong></p> <p>
        Cookies zijn bestanden met een kleine hoeveelheid gegevens die
        vaak worden gebruikt als unieke anonieme identificatoren. Deze worden verzonden
        naar uw browser vanaf de websites die u bezoekt en worden
        opgeslagen in het interne geheugen van uw apparaat.
    </p> <p>
        Deze dienst gebruikt deze "cookies" niet expliciet. Echter,
        de app kan wel gebruikmaken van code van derden en bibliotheken die "cookies" gebruiken om informatie te verzamelen en hun diensten te verbeteren.
        U heeft de mogelijkheid om deze cookies te accepteren of te weigeren
        en te weten wanneer een cookie naar uw apparaat wordt verzonden. Als u
        ervoor kiest om onze cookies te weigeren, kunt u mogelijk niet alle
        delen van deze dienst gebruiken.
    </p> <p><strong>Dienstverleners</strong></p> <p>
        Ik kan externe bedrijven en
        personen inhuren om de volgende redenen:
    </p> <ul><li>Om onze dienst te faciliteren;</li> <li>Om de dienst namens ons te leveren;</li> <li>Om dienstgerelateerde diensten uit te voeren; of</li> <li>Om ons te helpen bij het analyseren van hoe onze dienst wordt gebruikt.</li></ul> <p>
        Ik wil gebruikers van deze dienst informeren
        dat deze derden toegang hebben tot hun persoonlijke informatie. De reden hiervoor is om de taken uit te voeren die aan hen zijn toegewezen
        namens ons. Ze zijn echter verplicht om de informatie niet
        bekend te maken of voor geen enkel ander doel te gebruiken.
    </p> <p><strong>Veiligheid</strong></p> <p>
        Ik waardeer uw vertrouwen in het verstrekken van ons uw
        persoonlijke informatie, daarom streven we ernaar om commercieel
        aanvaardbare middelen te gebruiken om het te beschermen. Maar onthoud dat geen enkele methode
        van verzending via internet of elektronische opslag 100% veilig en betrouwbaar is, en we kunnen
        de absolute veiligheid ervan niet garanderen.
    </p> <p><strong>Links naar andere sites</strong></p> <p>
        Deze dienst kan links bevatten naar andere sites. Als u op
        een link van derden klikt, wordt u naar die site geleid. Let op
        dat deze externe sites niet door mij worden beheerd.
        Daarom raad ik u ten zeerste aan om het
        Privacybeleid van deze websites te bekijken. Ik heb
        geen controle over en aanvaard geen verantwoordelijkheid voor de inhoud,
        het privacybeleid of de praktijken van websites of diensten van derden.
    </p> <p><strong>Privacy van kinderen</strong></p> <div><p>
        Deze Diensten zijn niet gericht op personen jonger dan 13 jaar. Ik verzamel niet
        bewust persoonlijke informatie van personen jonger dan 13 jaar. In het geval
        ik ontdek dat een kind jonger dan 13 jaar ons heeft voorzien
        van persoonlijke informatie, verwijder ik deze onmiddellijk
        van onze servers. Als u een ouder of voogd bent en u weet dat
        uw kind ons persoonlijke informatie heeft verstrekt, neem dan contact met mij op zodat
        ik de nodige acties kan ondernemen.
        </p></div> <!----> <p><strong>Wijzigingen in dit privacybeleid</strong></p> <p>
        Ik kan ons privacybeleid van tijd tot tijd bijwerken. Daarom raad ik u aan om deze pagina
        regelmatig te controleren op wijzigingen. Ik zal
        u op de hoogte stellen van eventuele wijzigingen door het nieuwe privacybeleid op
        deze pagina te plaatsen.
    </p> <p>Dit beleid is van kracht vanaf 2024-02-22</p> <p><strong>Neem contact met ons op</strong></p> <p>
        Als u vragen of suggesties heeft over mijn
        privacybeleid, aarzel dan niet om contact met mij op te nemen via het e-mailadres quizejogosonline@gmail.com.</p>
   </div>
</template>

<script>
export default {
    name: 'DiarioEmocionalPrivacyPolicy'
}
</script>