<template>
    <div>
        <strong>Politique de Confidentialité</strong> <p>
        Quiz & Jogos Online a créé l'application Journal des Émotions en tant qu'application avec publicité. Ce SERVICE est fourni par
        Quiz & Jogos Online gratuitement et est destiné à être utilisé tel quel.
    </p> <p>
        Cette page est utilisée pour informer les visiteurs de mes politiques concernant la collecte, l'utilisation et la divulgation d'informations personnelles
        si quelqu'un décide d'utiliser mon service.
    </p> <p>
        Si vous choisissez d'utiliser mon service, vous acceptez la collecte et l'utilisation des informations en relation avec cette politique. Les informations personnelles que je collecte sont
        utilisées pour fournir et améliorer le service. Je n'utiliserai ni ne partagerai vos informations avec
        personne, sauf tel que décrit dans cette politique de confidentialité.
    </p> <p>
        Les termes utilisés dans cette politique de confidentialité ont les mêmes significations
        que dans nos conditions générales, accessibles sur
        Journal des Émotions, sauf indication contraire dans cette politique de confidentialité.
    </p> <p><strong>Collecte et Utilisation des Informations</strong></p> <p>
        Pour une meilleure expérience lors de l'utilisation de notre service, je peux vous demander de nous fournir certaines informations personnelles
        identifiables, y compris, mais sans s'y limiter, le nom, l'e-mail. Les informations que
        je demande seront conservées sur votre appareil et ne seront en aucun cas collectées par moi.
    </p> <div><p>
        L'application utilise des services tiers qui peuvent collecter
        des informations utilisées pour vous identifier.
        </p> <p>
        Lien vers la politique de confidentialité des fournisseurs de services tiers utilisés
        par l'application
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Services Google Play</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics pour Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/privacy_policy" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Données de Journalisation</strong></p> <p>
        Je tiens à vous informer que chaque fois que
        vous utilisez mon service, en cas d'erreur dans l'application
        je collecte des données et des informations (via des produits tiers)
        sur votre téléphone appelées données de journalisation. Ces données de journalisation peuvent
        inclure des informations telles que l'adresse IP de votre appareil, le nom de l'appareil, la version du système d'exploitation, la
        configuration de l'application lors de l'utilisation de mon service,
        l'heure et la date de votre utilisation du service et d'autres
        statistiques.
    </p> <p><strong>Cookies</strong></p> <p>
        Les cookies sont des fichiers contenant une petite quantité de données qui sont
        couramment utilisés comme identifiants uniques anonymes. Ceux-ci sont envoyés
        à votre navigateur depuis les sites web que vous visitez et sont
        stockés dans la mémoire interne de votre appareil.
    </p> <p>
        Ce service n'utilise pas explicitement ces "cookies". Cependant,
        l'application peut utiliser du code tiers et des bibliothèques qui utilisent des
        "cookies" pour collecter des informations et améliorer leurs services.
        Vous avez la possibilité d'accepter ou de refuser ces cookies
        et de savoir quand un cookie est envoyé à votre appareil. Si vous
        choisissez de refuser nos cookies, vous pourriez ne pas pouvoir utiliser certaines
        parties de ce service.
    </p> <p><strong>Prestataires de Services</strong></p> <p>
        Je peux employer des sociétés tierces et
        des individus pour les raisons suivantes :
    </p> <ul><li>Pour faciliter notre service ;</li> <li>Pour fournir le service en notre nom ;</li> <li>Pour effectuer des services liés au service ; ou</li> <li>Pour nous aider à analyser comment notre service est utilisé.</li></ul> <p>
        Je tiens à informer les utilisateurs de ce service
        que ces tiers ont accès à leurs informations personnelles. La raison en est d'exécuter les tâches qui leur sont assignées
        en notre nom. Cependant, ils sont tenus de ne pas
        divulguer ou utiliser les informations à d'autres fins.
    </p> <p><strong>Sécurité</strong></p> <p>
        Je valorise votre confiance en nous fournissant vos
        informations personnelles, c'est pourquoi nous nous efforçons d'utiliser des moyens
        commercialement acceptables pour les protéger. Mais rappelez-vous qu'aucune méthode
        de transmission sur Internet ou de stockage électronique n'est 100% sûre et fiable, et nous ne pouvons
        garantir sa sécurité absolue.
    </p> <p><strong>Liens vers d'Autres Sites</strong></p> <p>
        Ce service peut contenir des liens vers d'autres sites. Si vous cliquez sur
        un lien tiers, vous serez redirigé vers ce site. Notez
        que ces sites externes ne sont pas exploités par moi.
        Par conséquent, je vous recommande vivement de consulter la
        Politique de Confidentialité de ces sites web. Je n'ai
        aucun contrôle et n'assume aucune responsabilité quant au contenu,
        aux politiques de confidentialité ou aux pratiques des sites ou services tiers.
    </p> <p><strong>Vie Privée des Enfants</strong></p> <div><p>
        Ces Services ne s'adressent pas aux personnes de moins de 13 ans. Je ne collecte pas
        sciemment d'informations personnelles auprès de personnes de moins de 13 ans. Dans le cas
        où je découvre qu'un enfant de moins de 13 ans nous a fourni
        des informations personnelles, je les supprime immédiatement
        de nos serveurs. Si vous êtes parent ou tuteur et que vous savez que
        votre enfant nous a fourni des informations personnelles, veuillez me contacter afin que
        je puisse prendre les mesures nécessaires.
        </p></div> <!----> <p><strong>Modifications de cette Politique de Confidentialité</strong></p> <p>
        Je peux mettre à jour notre Politique de Confidentialité de
        temps à autre. Par conséquent, je vous conseille de consulter cette page
        périodiquement pour toute modification. Je
        vous informerai de toute modification en publiant la nouvelle Politique de Confidentialité sur
        cette page.
    </p> <p>Cette politique est effective à partir du 2024-02-22</p> <p><strong>Contactez-Nous</strong></p> <p>
        Si vous avez des questions ou des suggestions concernant ma
        Politique de Confidentialité, n'hésitez pas à me contacter à l'adresse e-mail quizejogosonline@gmail.com.</p>
   </div>
</template>

<script>
export default {
    name: 'DiarioEmocionalPrivacyPolicy'
}
</script>