<template>
    <div>
        <strong>Datenschutzrichtlinie</strong> <p>
        Quiz & Jogos Online hat die App Emotionales Tagebuch als
        eine werbegestützte App entwickelt. DIESE DIENSTLEISTUNG wird von
        Quiz & Jogos Online kostenlos zur Verfügung gestellt und ist zur Verwendung wie sie ist gedacht.
    </p> <p>
        Diese Seite wird verwendet, um Besucher über meine
        Richtlinien zur Sammlung, Verwendung und Offenlegung von persönlichen
        Informationen zu informieren, falls jemand beschließt, meinen Dienst zu nutzen.
    </p> <p>
        Wenn Sie sich dafür entscheiden, meinen Dienst zu nutzen, stimmen Sie der
        Erfassung und Verwendung von Informationen im Zusammenhang mit dieser
        Richtlinie zu. Die persönlichen Informationen, die ich sammle, werden verwendet, um
        den Dienst bereitzustellen und zu verbessern. Ich werde Ihre Informationen nicht verwenden oder teilen mit
        jemand anderem, wie in dieser Datenschutzrichtlinie beschrieben.
    </p> <p>
        Die in dieser Datenschutzrichtlinie verwendeten Begriffe haben dieselbe Bedeutung
        wie in unseren Allgemeinen Geschäftsbedingungen, die unter
        Emotionales Tagebuch zugänglich sind, sofern in dieser Datenschutzrichtlinie nicht anders definiert.
    </p> <p><strong>Datenerfassung und -verwendung</strong></p> <p>
        Für eine bessere Erfahrung bei der Nutzung unseres Dienstes kann ich Sie bitten, uns bestimmte persönliche
        identifizierbare Informationen zur Verfügung zu stellen, einschließlich, aber nicht beschränkt auf Name, E-Mail. Die Informationen, die
        ich anfrage, werden auf Ihrem Gerät gespeichert und werden in keiner Weise von mir erfasst.
    </p> <div><p>
        Die App verwendet Dienste von Drittanbietern, die möglicherweise
        Informationen sammeln, die zur Identifizierung verwendet werden können.
        </p> <p>
        Link zur Datenschutzrichtlinie der Drittanbieter-Dienstleister, die verwendet werden
        von der App
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play-Dienste</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=de" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics für Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/privacy_policy" target="_blank" rel="noopener noreferrer">OneSignal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Protokolldaten</strong></p> <p>
        Ich möchte Sie informieren, dass jedes Mal, wenn Sie
        meinen Dienst nutzen, im Falle eines Fehlers in der App
        ich Daten und Informationen (über Produkte von Drittanbietern)
        auf Ihrem Telefon namens Protokolldaten erfassen. Diese Protokolldaten können
        Informationen wie die IP-Adresse Ihres Geräts, den Gerätenamen, die Betriebssystemversion, die
        Konfiguration der App bei der Nutzung meines Dienstes,
        die Zeit und das Datum Ihrer Nutzung des Dienstes und andere
        Statistiken enthalten.
    </p> <p><strong>Cookies</strong></p> <p>
        Cookies sind Dateien mit einer kleinen Menge Daten, die
        häufig als eindeutige anonyme Identifikatoren verwendet werden. Diese werden gesendet
        an Ihren Browser von den Websites, die Sie besuchen, und werden
        im internen Speicher Ihres Geräts gespeichert.
    </p> <p>
        Dieser Dienst verwendet diese "Cookies" nicht explizit. Allerdings
        kann die App Code von Drittanbietern und Bibliotheken verwenden, die "Cookies" verwenden, um Informationen zu sammeln und ihre Dienste zu verbessern.
        Sie haben die Möglichkeit, diese Cookies zu akzeptieren oder abzulehnen
        und zu wissen, wann ein Cookie an Ihr Gerät gesendet wird. Wenn Sie
        sich entscheiden, unsere Cookies abzulehnen, können Sie möglicherweise nicht alle
        Teile dieses Dienstes nutzen.
    </p> <p><strong>Dienstleister</strong></p> <p>
        Ich kann externe Unternehmen und
        Einzelpersonen beauftragen aus den folgenden Gründen:
    </p> <ul><li>Um unseren Service zu erleichtern;</li> <li>Um den Service in unserem Namen bereitzustellen;</li> <li>Um Service-bezogene Dienste durchzuführen; oder</li> <li>Um uns bei der Analyse zu helfen, wie unser Service genutzt wird.</li></ul> <p>
        Ich möchte die Benutzer dieses Dienstes informieren,
        dass diese Dritten Zugriff auf ihre persönlichen Informationen haben. Der Grund dafür ist, die ihnen zugewiesenen Aufgaben zu erfüllen
        in unserem Namen. Sie sind jedoch verpflichtet, die Informationen nicht
        offen zu legen oder für kein anderes Ziel zu verwenden.
    </p> <p><strong>Sicherheit</strong></p> <p>
        Ich schätze Ihr Vertrauen, uns Ihre
        persönlichen Informationen zur Verfügung zu stellen, deshalb bemühen wir uns, kommerziell
        akzeptable Mittel zu verwenden, um es zu schützen. Aber denken Sie daran, dass keine Methode
        der Übertragung über das Internet oder der elektronischen Speicherung zu 100 % sicher und zuverlässig ist, und wir können
        die absolute Sicherheit nicht garantieren.
    </p> <p><strong>Links zu anderen Websites</strong></p> <p>
        Dieser Dienst kann Links zu anderen Websites enthalten. Wenn Sie auf
        einen Link eines Drittanbieters klicken, werden Sie zu dieser Website weitergeleitet. Beachten Sie
        dass diese externen Websites nicht von mir betrieben werden.
        Daher empfehle ich Ihnen dringend, das
        Datenschutzrichtlinie dieser Websites zu überprüfen. Ich habe
        keine Kontrolle über und übernehme keine Verantwortung für den Inhalt,
        die Datenschutzrichtlinien oder Praktiken von Websites oder Diensten von Drittanbietern.
    </p> <p><strong>Datenschutz von Kindern</strong></p> <div><p>
        Diese Dienste richten sich nicht an Personen unter 13 Jahren. Ich sammle nicht
        wissentlich persönliche Informationen von Personen unter 13 Jahren. Im Falle
        dass ich feststelle, dass ein Kind unter 13 Jahren uns zur Verfügung gestellt hat
        persönliche Informationen, lösche ich diese sofort
        von unseren Servern. Wenn Sie Eltern oder Erziehungsberechtigte sind und wissen, dass
        Ihr Kind uns persönliche Informationen zur Verfügung gestellt hat, nehmen Sie bitte Kontakt mit mir auf, damit
        ich die erforderlichen Maßnahmen ergreifen kann.
        </p></div> <!----> <p><strong>Änderungen an dieser Datenschutzrichtlinie</strong></p> <p>
        Ich kann unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Deshalb empfehle ich Ihnen, diese Seite
        regelmäßig auf Änderungen zu überprüfen. Ich werde
        Sie über etwaige Änderungen informieren, indem ich die neue Datenschutzrichtlinie auf
        dieser Seite veröffentliche.
    </p> <p>Diese Richtlinie ist ab dem 2024-02-22 wirksam</p> <p><strong>Kontaktiere uns</strong></p> <p>
        Wenn Sie Fragen oder Anregungen zu meiner
        Datenschutzrichtlinie haben, zögern Sie bitte nicht, mich unter der E-Mail-Adresse quizejogosonline@gmail.com zu kontaktieren.</p>
   </div>
</template>

<script>
export default {
    name: 'DiarioEmocionalPrivacyPolicy'
}
</script>