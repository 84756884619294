import MainScreen from "../screens/MainScreen.vue";
import PrivacyPolicy from "../screens/privacy_policies/PrivacyPolicy.vue";
import DiarioEmocionalPrivacyPolicyEN from "../screens/privacy_policies/diario_emocional/en/DiarioEmocionalPrivacyPolicy.vue";
import DiarioEmocionalPrivacyPolicyPT from "../screens/privacy_policies/diario_emocional/pt/DiarioEmocionalPrivacyPolicy.vue";
import DiarioEmocionalPrivacyPolicyES from "../screens/privacy_policies/diario_emocional/es/DiarioEmocionalPrivacyPolicy.vue";
import DiarioEmocionalPrivacyPolicyFR from "../screens/privacy_policies/diario_emocional/fr/DiarioEmocionalPrivacyPolicy.vue";
import DiarioEmocionalPrivacyPolicyIT from "../screens/privacy_policies/diario_emocional/it/DiarioEmocionalPrivacyPolicy.vue";
import DiarioEmocionalPrivacyPolicyNL from "../screens/privacy_policies/diario_emocional/nl/DiarioEmocionalPrivacyPolicy.vue";
import DiarioEmocionalPrivacyPolicyDE from "../screens/privacy_policies/diario_emocional/de/DiarioEmocionalPrivacyPolicy.vue";

import TruthOrDarePrivacyPolicyEN from "../screens/privacy_policies/truth_or_dare/en/TruthOrDarePrivacyPolice.vue";
import WhiteNoisePrivacyPolicyEN from "../screens/privacy_policies/white_noise/en/WhiteNoisePrivacyPolice.vue";

import TermsAndContitions from "../screens/terms_and_conditions/TermsAndConditions.vue";
import DiarioEmocionalTermsAndContitionsEN from "../screens/terms_and_conditions/diario_emocional/en/DiarioEmocionalTermsAndConditions.vue";
import DiarioEmocionalTermsAndContitionsPT from "../screens/terms_and_conditions/diario_emocional/pt/DiarioEmocionalTermsAndConditions.vue";
import DiarioEmocionalTermsAndContitionsES from "../screens/terms_and_conditions/diario_emocional/es/DiarioEmocionalTermsAndConditions.vue";
import DiarioEmocionalTermsAndContitionsFR from "../screens/terms_and_conditions/diario_emocional/fr/DiarioEmocionalTermsAndConditions.vue";
import DiarioEmocionalTermsAndContitionsIT from "../screens/terms_and_conditions/diario_emocional/it/DiarioEmocionalTermsAndConditions.vue";
import DiarioEmocionalTermsAndContitionsNL from "../screens/terms_and_conditions/diario_emocional/nl/DiarioEmocionalTermsAndConditions.vue";
import DiarioEmocionalTermsAndContitionsDE from "../screens/terms_and_conditions/diario_emocional/de/DiarioEmocionalTermsAndConditions.vue";

import HowToDeleteAccount from "../screens/how_to_delete_account/HowToDeleteAccount.vue";
import DiarioEmocionalHowToDeleteAccountEN from "../screens/how_to_delete_account/diario_emocional/en/DiarioEmocionalHowToDeleteAccountEN.vue";

import AppAdsTxtScreen from "../screens/app_ads_txt/AppAdsTxtScreen.vue";

const routes = [
    {
        path: '/',
        name: 'main',
        component: MainScreen,
    },
    {
        path: '/app-ads.txt',
        name: 'app_ads_txt',
        component: AppAdsTxtScreen,
    },
    {
        name: 'privacy_policy',
        path: '/privacy_policy/',
        component: PrivacyPolicy,
        children: [
            {
                path: 'en/diario_emocional',
                component: DiarioEmocionalPrivacyPolicyEN,
            },
            {
                path: 'pt/diario_emocional',
                component: DiarioEmocionalPrivacyPolicyPT,
            },
            {
                path: 'es/diario_emocional',
                component: DiarioEmocionalPrivacyPolicyES,
            },
            {
                path: 'fr/diario_emocional',
                component: DiarioEmocionalPrivacyPolicyFR,
            },
            {
                path: 'it/diario_emocional',
                component: DiarioEmocionalPrivacyPolicyIT,
            },
            {
                path: 'nl/diario_emocional',
                component: DiarioEmocionalPrivacyPolicyNL,
            },
            {
                path: 'de/diario_emocional',
                component: DiarioEmocionalPrivacyPolicyDE,
            },
            // 
            {
                path: 'en/truth_or_dare',
                component: TruthOrDarePrivacyPolicyEN,
            },
            //
            {
                path: 'en/white_noise',
                component: WhiteNoisePrivacyPolicyEN,
            },
        ],
    },
    {
        name: 'terms_and_conditions',
        path: '/terms_and_conditions/',
        component: TermsAndContitions,
        children: [
            {
                path: 'en/diario_emocional',
                component: DiarioEmocionalTermsAndContitionsEN,
            },
            {
                path: 'pt/diario_emocional',
                component: DiarioEmocionalTermsAndContitionsPT,
            },
            {
                path: 'es/diario_emocional',
                component: DiarioEmocionalTermsAndContitionsES,
            },
            {
                path: 'fr/diario_emocional',
                component: DiarioEmocionalTermsAndContitionsFR,
            },
            {
                path: 'it/diario_emocional',
                component: DiarioEmocionalTermsAndContitionsIT,
            },
            {
                path: 'nl/diario_emocional',
                component: DiarioEmocionalTermsAndContitionsNL,
            },
            {
                path: 'de/diario_emocional',
                component: DiarioEmocionalTermsAndContitionsDE,
            },
        ],
    },
    {
        name: 'how_to_delete',
        path: '/how_to_delete_account/',
        component: HowToDeleteAccount,
        children: [
            {
                path: 'en/diario_emocional',
                component: DiarioEmocionalHowToDeleteAccountEN,
            },
        ],
    },
];

export default routes;