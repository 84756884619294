<template>
    <div>
        <strong>Allgemeine Geschäftsbedingungen</strong> <p>
            Durch das Herunterladen oder die Nutzung der App gelten diese Bedingungen automatisch für Sie - stellen Sie daher sicher, dass Sie sie sorgfältig lesen, bevor Sie die App verwenden. Es ist nicht erlaubt, die App, einen Teil der App oder unsere Marken auf irgendeine Weise zu kopieren oder zu modifizieren. Sie dürfen nicht versuchen, den Quellcode der App zu extrahieren, und Sie dürfen auch nicht versuchen, die App in andere Sprachen zu übersetzen oder abgeleitete Versionen zu erstellen. Die App selbst sowie alle Marken, Urheberrechte, Datenbankrechte und sonstigen geistigen Eigentumsrechte, die damit verbunden sind, bleiben Eigentum von Quiz & Jogos Online.
        </p> <p>
            Quiz & Jogos Online verpflichtet sich, sicherzustellen, dass die App so nützlich und effizient wie möglich ist. Aus diesem Grund behalten wir uns das Recht vor, jederzeit und aus jedem Grund Änderungen an der App vorzunehmen oder für ihre Dienste Gebühren zu erheben. Wir werden Ihnen niemals Kosten für die App oder ihre Dienste in Rechnung stellen, ohne Ihnen genau zu erklären, wofür Sie bezahlen.
        </p> <p>
            Die App Emotionales Tagebuch speichert und verarbeitet personenbezogene Daten, die Sie uns zur Verfügung gestellt haben, um meinen Service bereitzustellen. Es liegt in Ihrer Verantwortung, Ihr Telefon und den Zugang zur App sicher zu halten. Wir empfehlen Ihnen daher, Ihr Telefon nicht zu jailbreaken oder zu rooten, was den Vorgang zum Entfernen von Softwarebeschränkungen und -einschränkungen darstellt, die vom offiziellen Betriebssystem Ihres Geräts auferlegt werden. Dies könnte Ihr Telefon anfällig für Malware/Viren/bösartige Programme machen, die Sicherheitsfunktionen Ihres Telefons beeinträchtigen und bedeuten, dass die App Emotionales Tagebuch möglicherweise nicht richtig oder gar nicht funktioniert.
        </p> <div><p>
            Die App verwendet Dienste von Drittanbietern, die ihre Allgemeinen Geschäftsbedingungen erklären.
            </p> <p>
            Link zu den Allgemeinen Geschäftsbedingungen von Drittanbieter-Dienstleistern, die von der App verwendet werden
            </p> <ul><li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play-Dienste</a></li><li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google Analytics für Firebase</a></li><li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/tos" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
            Sie sollten wissen, dass es bestimmte Dinge gibt, für die Quiz & Jogos Online keine Verantwortung übernimmt. Bestimmte Funktionen der App erfordern, dass die App eine aktive Internetverbindung hat. Die Verbindung kann WLAN sein oder von Ihrem Mobilfunkanbieter bereitgestellt werden, aber Quiz & Jogos Online kann nicht dafür verantwortlich gemacht werden, wenn die App nicht mit allen Funktionen funktioniert, wenn Sie keinen Zugang zu WLAN haben und Ihr Datenvolumen aufgebraucht ist.
        </p> <p></p> <p>
            Wenn Sie die App außerhalb eines WLAN-Bereichs verwenden, sollten Sie daran denken, dass die Bedingungen des Vertrags mit Ihrem Mobilfunkanbieter weiterhin gelten. Daher kann Ihr Mobilfunkanbieter Ihnen möglicherweise die Kosten für Daten während der Verbindung mit der App in Rechnung stellen oder andere Drittanbieterkosten. Durch die Verwendung der App akzeptieren Sie die Verantwortung für solche Kosten, einschließlich Roaming-Gebühren für Daten, wenn Sie die App außerhalb Ihres Heimatgebiets (dh Region oder Land) verwenden, ohne Datenroaming auszuschalten. Wenn Sie nicht der Rechnungszahler für das Gerät sind, auf dem Sie die App verwenden, beachten Sie bitte, dass wir davon ausgehen, dass Sie die Erlaubnis des Rechnungszahlers zur Verwendung der App erhalten haben.
        </p> <p>
            Ebenso kann Quiz & Jogos Online nicht immer für die Art und Weise verantwortlich gemacht werden, wie Sie die App verwenden, dh Sie müssen sicherstellen, dass Ihr Gerät aufgeladen ist: Wenn der Akku leer ist und Sie ihn nicht einschalten können, um den Service zu nutzen, kann Quiz & Jogos Online keine Verantwortung übernehmen.
        </p> <p>
            In Bezug auf die Verantwortung von Quiz & Jogos Online für Ihre Nutzung der App ist es beim Gebrauch der App wichtig zu bedenken, dass wir zwar bemüht sind sicherzustellen, dass sie jederzeit aktualisiert und korrekt ist, wir jedoch von Dritten abhängig sind, um uns Informationen zur Verfügung zu stellen, damit wir sie Ihnen zur Verfügung stellen können. Quiz & Jogos Online übernimmt keine Haftung für Verluste, direkt oder indirekt, die Sie erleiden, wenn Sie sich ausschließlich auf diese Funktion der App verlassen.
        </p> <p>
            Irgendwann möchten wir möglicherweise die App aktualisieren. Derzeit ist die App auf Android verfügbar: Die Anforderungen für das System (und für alle zusätzlichen Systeme, zu denen wir beschließen, die Verfügbarkeit der App zu erweitern) können sich ändern, und Sie müssen die Updates herunterladen, wenn Sie die App weiterhin verwenden möchten. Quiz & Jogos Online verspricht nicht, die App immer so zu aktualisieren, dass sie für Sie relevant ist und/oder mit der Android-Version funktioniert, die Sie auf Ihrem Gerät installiert haben. Sie versprechen jedoch, Updates für die Anwendung immer dann zu akzeptieren, wenn sie Ihnen angeboten werden. Wir möchten auch die Bereitstellung der App einstellen und die Verwendung jederzeit ohne Ankündigung beenden. Sofern wir Ihnen nichts anderes mitteilen, enden bei Beendigung eines solchen Vertrags (a) die Ihnen in diesen Bedingungen gewährten Rechte und Lizenzen; (b) Sie müssen die App verwenden stoppen und es gegebenenfalls von Ihrem Gerät löschen.
        </p> <p><strong>Änderungen an diesen Allgemeinen Geschäftsbedingungen</strong></p> <p>
            Ich kann unsere Allgemeinen Geschäftsbedingungen von Zeit zu Zeit aktualisieren. Daher empfehle ich Ihnen, diese Seite regelmäßig auf Änderungen zu überprüfen. Ich werde Sie über etwaige Änderungen informieren, indem ich die neuen Allgemeinen Geschäftsbedingungen auf dieser Seite veröffentliche.
        </p> <p>
            Diese Allgemeinen Geschäftsbedingungen gelten ab dem 2024-02-22
        </p> <p><strong>Kontaktieren Sie uns</strong></p> <p>
            Wenn Sie Fragen oder Vorschläge zu meinen Allgemeinen Geschäftsbedingungen haben, zögern Sie bitte nicht, mich unter der E-Mail-Adresse quizejogosonline@gmail.com zu kontaktieren.
        </p>

    </div>
</template>

<script>
export default {
    name: "DiarioEmocionalTermsAndContions"
}
</script>