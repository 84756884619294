<template>
    <div>
        <h3>How to delete your account on Emotion's diary app:</h3>
        <ul>
            <li>Click on the Settings icon at the top border of the home screen</li>
            <li>Once redirected to the Settings page, click on the red button to delete your account</li>
        </ul>
        <p>All you diary data will be removed from our datatabases, and you will be signed out.</p>
        <p>You will be still able to Login later if you desire to, but all your dirary data won't exist anymore.</p>
    </div>
</template>

<script>
export default {
    name: "DiarioEmocionalHowToDeleteAccountEN"
}
</script>