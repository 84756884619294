<template>
    <div>
        <strong>Política de Privacidad</strong> <p>
        Quiz & Jogos Online construyó la aplicación Diario Emocional como
        una aplicación con publicidad. Este SERVICIO es proporcionado por
        Quiz & Jogos Online de forma gratuita y está destinado a ser utilizado tal como está.
    </p> <p>
        Esta página se utiliza para informar a los visitantes sobre
        mis políticas con la recopilación, uso y divulgación de Información Personal
        en caso de que alguien decida utilizar mi Servicio.
    </p> <p>
        Si eliges utilizar mi Servicio, entonces aceptas la recopilación y uso
        de información en relación con esta política. La Información Personal que recopilo es
        utilizada para proporcionar y mejorar el Servicio. No utilizaré ni compartiré tu información con
        nadie, excepto como se describe en esta Política de Privacidad.
    </p> <p>
        Los términos utilizados en esta Política de Privacidad tienen los mismos significados
        que en nuestros Términos y Condiciones, accesibles en
        Diario Emocional, a menos que se defina de otra manera en esta Política de Privacidad.
    </p> <p><strong>Recopilación y Uso de Información</strong></p> <p>
        Para una mejor experiencia, al utilizar nuestro Servicio, puedo
        requerir que nos proporciones cierta información de identificación personal, incluyendo, pero no limitado a nombre, correo electrónico. La información que
        solicito se mantendrá en tu dispositivo y no será recopilada por mí de ninguna manera.
    </p> <div><p>
        La aplicación utiliza servicios de terceros que pueden recopilar
        información utilizada para identificarte.
        </p> <p>
        Enlace a la política de privacidad de los proveedores de servicios de terceros utilizados
        por la aplicación
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Servicios de Google Play</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics para Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/privacy_policy" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Datos de Registro</strong></p> <p>
        Quiero informarte que siempre que
        uses mi Servicio, en caso de un error en la aplicación
        recopilo datos e información (a través de productos de terceros)
        en tu teléfono llamados Datos de Registro. Estos Datos de Registro pueden
        incluir información como la dirección IP de tu dispositivo, nombre del dispositivo, versión del sistema operativo, la
        configuración de la aplicación cuando utiliza mi Servicio,
        la hora y la fecha de tu uso del Servicio y otras
        estadísticas.
    </p> <p><strong>Cookies</strong></p> <p>
        Las cookies son archivos con una pequeña cantidad de datos que se utilizan
        comúnmente como identificadores únicos anónimos. Estos se envían
        a tu navegador desde los sitios web que visitas y se
        almacenan en la memoria interna de tu dispositivo.
    </p> <p>
        Este Servicio no utiliza estas “cookies” de forma explícita. Sin embargo,
        la aplicación puede utilizar código de terceros y bibliotecas que utilizan
        “cookies” para recopilar información y mejorar sus servicios.
        Tienes la opción de aceptar o rechazar estas cookies
        y saber cuándo se está enviando una cookie a tu dispositivo. Si
        optas por rechazar nuestras cookies, es posible que no puedas utilizar algunas
        partes de este Servicio.
    </p> <p><strong>Proveedores de Servicios</strong></p> <p>
        Puedo contratar empresas de terceros e
        individuos por las siguientes razones:
    </p> <ul><li>Para facilitar nuestro Servicio;</li> <li>Para proporcionar el Servicio en nuestro nombre;</li> <li>Para realizar servicios relacionados con el Servicio; o</li> <li>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</li></ul> <p>
        Quiero informar a los usuarios de este Servicio
        que estos terceros tienen acceso a su Información Personal. El motivo es realizar las tareas asignadas a
        ellos en nuestro nombre. Sin embargo, están obligados a no
        divulgar ni utilizar la información para ningún otro propósito.
    </p> <p><strong>Seguridad</strong></p> <p>
        Valoramos tu confianza al proporcionarnos tu
        Información Personal, por lo que nos esforzamos por utilizar medios
        comercialmente aceptables para protegerla. Pero recuerda que ningún método
        de transmisión por Internet o método de almacenamiento electrónico es 100% seguro y confiable, y no podemos
        garantizar su seguridad absoluta.
    </p> <p><strong>Enlaces a Otros Sitios</strong></p> <p>
        Este Servicio puede contener enlaces a otros sitios. Si haces clic en
        un enlace de terceros, serás dirigido a ese sitio. Ten en cuenta
        que estos sitios externos no son operados por mí.
        Por lo tanto, te recomiendo encarecidamente que revises la
        Política de Privacidad de estos sitios web. No tengo
        control y no asumo responsabilidad por el contenido,
        políticas de privacidad o prácticas de sitios o servicios de terceros.
    </p> <p><strong>Privacidad de los Niños</strong></p> <div><p>
        Estos Servicios no están dirigidos a personas menores de 13 años. No recopilo
        conscientemente información de identificación personal de niños menores de 13 años. En el caso
        de que descubra que un niño menor de 13 años me ha proporcionado
        información personal, la elimino inmediatamente
        de nuestros servidores. Si eres padre, madre o tutor y estás al tanto de que
        tu hijo nos ha proporcionado información personal, por favor contáctame para que
        pueda tomar las medidas necesarias.
        </p></div> <!----> <p><strong>Cambios en esta Política de Privacidad</strong></p> <p>
        Puedo actualizar nuestra Política de Privacidad de
        vez en cuando. Por lo tanto, te recomendamos que revises esta página
        periódicamente para ver si hay cambios. Te
        notificaré de cualquier cambio publicando la nueva Política de Privacidad en
        esta página.
    </p> <p>Esta política es efectiva a partir del 2024-02-22</p> <p><strong>Contáctanos</strong></p> <p>
        Si tienes alguna pregunta o sugerencia sobre mi
        Política de Privacidad, no dudes en contactarme por correo electrónico a quizejogosonline@gmail.com.</p>
    </div>
</template>

<script>
export default {
    name: 'DiarioEmocionalPrivacyPolicy'
}
</script>