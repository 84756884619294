<template>
    <div>
        <strong>Termini e Condizioni</strong> <p>
            Scaricando o utilizzando l'applicazione, questi termini si applicheranno automaticamente a te; quindi, assicurati di leggerli attentamente prima di utilizzare l'applicazione. Non è consentito copiare o modificare l'applicazione, nessuna parte dell'applicazione, né i nostri marchi commerciali in alcun modo. Non ti è consentito tentare di estrarre il codice sorgente dell'applicazione, e non dovresti neanche cercare di tradurre l'applicazione in altre lingue o creare versioni derivate. L'applicazione stessa, e tutti i marchi commerciali, i diritti d'autore, i diritti del database e altri diritti di proprietà intellettuale ad esso correlati, continuano a essere di proprietà di Quiz & Jogos Online.
        </p> <p>
            Quiz & Jogos Online si impegna a garantire che l'applicazione sia il più utile ed efficiente possibile. Per questo motivo, ci riserviamo il diritto di apportare modifiche all'applicazione o di addebitare i suoi servizi, in qualsiasi momento e per qualsiasi motivo. Non ti addebiteremo mai per l'applicazione o i suoi servizi senza chiarirti esattamente per cosa stai pagando.
        </p> <p>
            L'applicazione Diario Emotivo memorizza e elabora dati personali che ci hai fornito, per fornire il mio Servizio. È tua responsabilità mantenere sicuro il tuo telefono e l'accesso all'applicazione. Pertanto, ti raccomandiamo di non fare il jailbreak o il root del tuo telefono, che è il processo di rimozione delle restrizioni e limitazioni del software imposte dal sistema operativo ufficiale del tuo dispositivo. Ciò potrebbe rendere il tuo telefono vulnerabile a malware/virus/programmi dannosi, compromettere le funzionalità di sicurezza del tuo telefono e potrebbe significare che l'applicazione Diario Emotivo potrebbe non funzionare correttamente o affatto.
        </p> <div><p>
            L'applicazione utilizza servizi di terze parti che dichiarano i loro Termini e Condizioni.
            </p> <p>
            Link ai Termini e Condizioni dei fornitori di servizi di terze parti utilizzati dall'applicazione
            </p> <ul><li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Servizi Google Play</a></li><li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google Analytics per Firebase</a></li><li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/tos" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
            Devi essere consapevole che ci sono alcune cose per le quali Quiz & Jogos Online non si assumerà responsabilità. Alcune funzioni dell'applicazione richiederanno che l'applicazione abbia una connessione Internet attiva. La connessione può essere Wi-Fi o fornita dal tuo fornitore di rete mobile, ma Quiz & Jogos Online non può assumersi la responsabilità se l'applicazione non funziona con funzionalità complete se non hai accesso a Wi-Fi e non ti rimangono dati di connessione.
        </p> <p></p> <p>
            Se stai utilizzando l'applicazione al di fuori di un'area con Wi-Fi, devi ricordare che i termini dell'accordo con il tuo fornitore di rete mobile si applicheranno comunque. Di conseguenza, potresti essere addebitato dal tuo fornitore mobile per il costo dei dati durante la connessione all'applicazione, o altre tariffe di terze parti. Utilizzando l'applicazione, accetti la responsabilità di tali addebiti, inclusi addebiti per dati in roaming se utilizzi l'applicazione al di fuori del tuo territorio domestico (cioè, regione o paese) senza disattivare il roaming dati. Se non sei il pagatore della bolletta del dispositivo su cui stai utilizzando l'applicazione, considera che assumiamo che tu abbia ottenuto il permesso dal pagatore della bolletta per utilizzare l'applicazione.
        </p> <p>
            Allo stesso modo, Quiz & Jogos Online non può sempre assumersi la responsabilità per il modo in cui utilizzi l'applicazione, cioè devi assicurarti che il tuo dispositivo sia caricato: se si scarica e non puoi accenderlo per usufruire del Servizio, Quiz & Jogos Online non può accettare responsabilità.
        </p> <p>
            Per quanto riguarda la responsabilità di Quiz & Jogos Online per il tuo utilizzo dell'applicazione, quando stai utilizzando l'applicazione, è importante tenere presente che, sebbene cerchiamo di garantire che sia sempre aggiornata e corretta, dipendiamo da terze parti per fornirci informazioni in modo che possiamo metterle a tua disposizione. Quiz & Jogos Online non accetta alcuna responsabilità per eventuali perdite, dirette o indirette, che potresti subire a causa della tua fiducia esclusivamente in questa funzionalità dell'applicazione.
        </p> <p>
            A un certo punto, potremmo desiderare di aggiornare l'applicazione. Attualmente, l'applicazione è disponibile su Android: i requisiti per il sistema (e per eventuali altri sistemi che decidiamo di estendere la disponibilità dell'applicazione) potrebbero cambiare, e dovrai scaricare gli aggiornamenti se desideri continuare a utilizzare l'applicazione. Quiz & Jogos Online non promette di aggiornare sempre l'applicazione in modo che sia rilevante per te e/o funzioni con la versione di Android che hai installato sul tuo dispositivo. Tuttavia, ti impegni ad accettare sempre gli aggiornamenti dell'applicazione quando ti vengono offerti. Potremmo anche desiderare di smettere di fornire l'applicazione, e potremmo terminare l'uso di essa in qualsiasi momento senza preavviso a te. A meno che non ti diciamo diversamente, al termine di qualsiasi risoluzione, (a) i diritti e le licenze concessi a te in questi termini termineranno; (b) devi smettere di utilizzare l'applicazione e, se necessario, eliminarla dal tuo dispositivo.
        </p> <p><strong>Modifiche a Questi Termini e Condizioni</strong></p> <p>
            Potrei aggiornare i nostri Termini e Condizioni di volta in volta. Pertanto, ti consiglio di rivedere periodicamente questa pagina per eventuali modifiche. Ti informerò di eventuali modifiche pubblicando i nuovi Termini e Condizioni in questa pagina.
        </p> <p>
            Questi termini e condizioni sono efficaci dal 2024-02-22
        </p> <p><strong>Contattaci</strong></p> <p>
            Se hai domande o suggerimenti sui miei Termini e Condizioni, non esitare a contattarmi all'indirizzo quizejogosonline@gmail.com.
        </p>
    </div>
</template>

<script>
export default {
    name: "DiarioEmocionalTermsAndContions"
}
</script>