<template>
    <div>
        <strong>Conditions Générales</strong> <p>
            En téléchargeant ou en utilisant l'application, ces termes s'appliqueront automatiquement à vous ; vous devez donc vous assurer de les lire attentivement avant d'utiliser l'application. Il est interdit de copier ou de modifier l'application, toute partie de l'application, ou nos marques de commerce de quelque manière que ce soit. Vous n'êtes pas autorisé à tenter d'extraire le code source de l'application, et vous ne devez pas non plus essayer de traduire l'application dans d'autres langues ou de créer des versions dérivées. L'application elle-même, ainsi que toutes les marques de commerce, droits d'auteur, droits de base de données et autres droits de propriété intellectuelle qui y sont liés, continuent d'appartenir à Quiz & Jogos Online.
        </p> <p>
            Quiz & Jogos Online s'engage à garantir que l'application soit aussi utile et efficace que possible. Pour cette raison, nous nous réservons le droit d'apporter des modifications à l'application ou de facturer ses services, à tout moment et pour quelque raison que ce soit. Nous ne vous facturerons jamais l'application ou ses services sans vous expliquer clairement ce que vous payez.
        </p> <p>
            L'application Journal des Émotions stocke et traite les données personnelles que vous nous avez fournies, pour fournir mon Service. Il est de votre responsabilité de garder votre téléphone et l'accès à l'application sécurisés. Par conséquent, nous vous recommandons de ne pas jailbreaker ou rooter votre téléphone, c'est-à-dire de ne pas supprimer les restrictions et limitations logicielles imposées par le système d'exploitation officiel de votre appareil. Cela pourrait rendre votre téléphone vulnérable aux logiciels malveillants/virus/programmes malveillants, compromettre les fonctionnalités de sécurité de votre téléphone et signifier que l'application Journal des Émotions ne fonctionnera pas correctement, voire pas du tout.
        </p> <div><p>
            L'application utilise des services tiers qui déclarent leurs Conditions Générales.
            </p> <p>
            Lien vers les Conditions Générales des fournisseurs de services tiers utilisés par l'application
            </p> <ul><li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Services Google Play</a></li><li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google Analytics pour Firebase</a></li><li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/tos" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
            Vous devez savoir qu'il y a certaines choses pour lesquelles Quiz & Jogos Online ne prendra pas de responsabilité. Certaines fonctions de l'application nécessiteront que l'application ait une connexion Internet active. La connexion peut être Wi-Fi ou fournie par votre fournisseur de réseau mobile, mais Quiz & Jogos Online ne peut pas être tenu responsable si l'application ne fonctionne pas avec toutes ses fonctionnalités si vous n'avez pas accès au Wi-Fi et s'il ne vous reste plus de données de connexion.
        </p> <p></p> <p>
            Si vous utilisez l'application en dehors d'une zone Wi-Fi, vous devez vous rappeler que les termes de l'accord avec votre fournisseur de réseau mobile s'appliqueront toujours. Par conséquent, votre fournisseur mobile peut vous facturer le coût des données pendant la connexion à l'application, ou d'autres frais tiers. En utilisant l'application, vous acceptez la responsabilité de ces frais, y compris les frais d'itinérance des données si vous utilisez l'application en dehors de votre territoire domestique (c'est-à-dire, région ou pays) sans désactiver l'itinérance des données. Si vous n'êtes pas le payeur de la facture de l'appareil sur lequel vous utilisez l'application, veuillez noter que nous supposons que vous avez obtenu la permission du payeur de la facture pour utiliser l'application.
        </p> <p>
            De même, Quiz & Jogos Online ne peut pas toujours assumer la responsabilité de la façon dont vous utilisez l'application, c'est-à-dire, vous devez vous assurer que votre appareil est chargé : s'il se décharge et que vous ne pouvez pas le rallumer pour bénéficier du Service, Quiz & Jogos Online ne peut pas accepter la responsabilité.
        </p> <p>
            En ce qui concerne la responsabilité de Quiz & Jogos Online pour votre utilisation de l'application, lors de l'utilisation de l'application, il est important de garder à l'esprit que, bien que nous nous efforcions de garantir qu'elle soit toujours à jour et correcte, nous dépendons de tiers pour nous fournir des informations afin que nous puissions les mettre à votre disposition. Quiz & Jogos Online n'accepte aucune responsabilité pour toute perte, directe ou indirecte, que vous pourriez subir en raison de votre confiance exclusive dans cette fonctionnalité de l'application.
        </p> <p>
            À un moment donné, nous pourrions souhaiter mettre à jour l'application. Actuellement, l'application est disponible sur Android : les exigences du système (et pour tout autre système supplémentaire auquel nous déciderions d'étendre la disponibilité de l'application) pourraient changer, et vous devrez télécharger les mises à jour si vous souhaitez continuer à utiliser l'application. Quiz & Jogos Online ne promet pas de toujours mettre à jour l'application pour qu'elle soit pertinente pour vous et/ou qu'elle fonctionne avec la version d'Android que vous avez installée sur votre appareil. Cependant, vous vous engagez à toujours accepter les mises à jour de l'application lorsqu'elles vous sont proposées. Nous pourrions également souhaiter cesser de fournir l'application, et nous pourrions mettre fin à son utilisation à tout moment sans vous donner de préavis de résiliation. À moins que nous ne vous en informions autrement, à la résiliation de tout contrat, (a) les droits et licences qui vous sont accordés en vertu de ces conditions prendront fin ; (b) vous devez cesser d'utiliser l'application et, si nécessaire, la supprimer de votre appareil.
        </p> <p><strong>Changements à Ces Conditions Générales</strong></p> <p>
            Je pourrais mettre à jour nos Conditions Générales de temps en temps. Par conséquent, je vous conseille de consulter cette page régulièrement pour tout changement. Je vous informerai de tout changement en publiant les nouvelles Conditions Générales sur cette page.
        </p> <p>
            Ces termes et conditions sont en vigueur à partir du 2024-02-22
        </p> <p><strong>Contactez-Nous</strong></p> <p>
            Si vous avez des questions ou des suggestions concernant mes Conditions Générales, n'hésitez pas à me contacter à l'adresse quizejogosonline@gmail.com.
        </p>
    </div>
</template>

<script>
export default {
    name: "DiarioEmocionalTermsAndContions"
}
</script>