<template>
    <div>
        <strong>Politica sulla Privacy</strong> <p>
        Quiz & Jogos Online ha creato l'app Diario Emotivo come
        un'app con pubblicità. Questo SERVIZIO è fornito da
        Quiz & Jogos Online gratuitamente e è destinato all'uso come
        è.
    </p> <p>
        Questa pagina è utilizzata per informare i visitatori riguardo le
        mie politiche riguardanti la raccolta, l'uso e la divulgazione di Informazioni Personali
        nel caso in cui qualcuno decida di utilizzare il mio Servizio.
    </p> <p>
        Se scegli di utilizzare il mio Servizio, accetti
        la raccolta e l'uso delle informazioni in relazione a questa
        politica. Le Informazioni Personali che raccolgo vengono
        utilizzate per fornire e migliorare il Servizio. Non userò o condividerò le tue informazioni con
        nessuno, tranne come descritto in questa Politica sulla Privacy.
    </p> <p>
        I termini utilizzati in questa Politica sulla Privacy hanno gli stessi significati
        come nei nostri Termini e Condizioni, accessibili presso
        Diario Emotivo, a meno che non sia diversamente definito in questa Politica sulla Privacy.
    </p> <p><strong>Raccolta e Uso delle Informazioni</strong></p> <p>
        Per un'esperienza migliore, durante l'utilizzo del nostro Servizio, posso
        richiedere di fornirci determinate informazioni personali
        identificabili, inclusi, ma non limitati a nome, e-mail. Le informazioni che
        richiedo verranno conservate sul tuo dispositivo e non verranno raccolte da me in alcun modo.
    </p> <div><p>
        L'app utilizza servizi di terze parti che possono raccogliere
        informazioni utilizzate per identificarti.
        </p> <p>
        Link alla politica sulla privacy dei fornitori di servizi di terze parti utilizzati
        dall'applicazione
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Servizi Google Play</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics per Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/privacy_policy" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Dati di Registrazione</strong></p> <p>
        Desidero informarti che ogni volta che
        utilizzi il mio Servizio, in caso di errore nell'applicazione
        raccolgo dati e informazioni (tramite prodotti di terze parti)
        sul tuo telefono chiamati Dati di Registrazione. Questi Dati di Registrazione possono
        includere informazioni come l'indirizzo IP del tuo dispositivo, nome del dispositivo, versione del sistema operativo, la
        configurazione dell'applicazione quando utilizzi il mio Servizio,
        l'ora e la data del tuo utilizzo del Servizio e altre
        statistiche.
    </p> <p><strong>Cookies</strong></p> <p>
        I cookies sono file con una piccola quantità di dati che vengono utilizzati
        comunemente come identificatori univoci anonimi. Questi vengono inviati
        al tuo browser dai siti web che visiti e vengono
        memorizzati nella memoria interna del tuo dispositivo.
    </p> <p>
        Questo Servizio non utilizza esplicitamente questi "cookies". Tuttavia,
        l'app può utilizzare codice di terze parti e librerie che utilizzano
        "cookies" per raccogliere informazioni e migliorare i loro servizi.
        Hai la possibilità di accettare o rifiutare questi cookies
        e di sapere quando viene inviato un cookie al tuo dispositivo. Se
        scegli di rifiutare i nostri cookies, potresti non essere in grado di utilizzare alcune
        parti di questo Servizio.
    </p> <p><strong>Fornitori di Servizi</strong></p> <p>
        Posso impiegare aziende di terze parti e
        individui per i seguenti motivi:
    </p> <ul><li>Per facilitare il nostro Servizio;</li> <li>Per fornire il Servizio per nostro conto;</li> <li>Per eseguire servizi correlati al Servizio; o</li> <li>Per aiutarci ad analizzare come viene utilizzato il nostro Servizio.</li></ul> <p>
        Voglio informare gli utenti di questo Servizio
        che questi terzi hanno accesso alle loro Informazioni Personali. Il motivo è quello di eseguire le attività loro assegnate
        a nostro nome. Tuttavia, sono tenuti a non
        divulgare o utilizzare le informazioni per qualsiasi altro scopo.
    </p> <p><strong>Sicurezza</strong></p> <p>
        Apprezzo la tua fiducia nel fornirci le tue
        Informazioni Personali, quindi ci impegniamo a utilizzare mezzi
        commercialmente accettabili per proteggerle. Ma ricorda che nessun metodo
        di trasmissione via Internet o di archiviazione elettronica è al 100% sicuro e affidabile, e non possiamo
        garantire la sicurezza assoluta.
    </p> <p><strong>Link ad Altri Siti</strong></p> <p>
        Questo Servizio può contenere link ad altri siti. Se fai clic su
        un link di terze parti, verrai reindirizzato a quel sito. Nota
        che questi siti esterni non sono gestiti da me.
        Pertanto, ti consiglio vivamente di esaminare la
        Politica sulla Privacy di questi siti web. Non ho
        alcun controllo e non assumo responsabilità per il contenuto,
        le politiche sulla privacy o le pratiche di siti o servizi di terze parti.
    </p> <p><strong>Privacy dei Bambini</strong></p> <div><p>
        Questi Servizi non sono rivolti a persone di età inferiore ai 13 anni. Non raccolgo
        consapevolmente informazioni personali da minori di 13 anni. Nel caso
        in cui scopra che un bambino di età inferiore ai 13 anni ci ha fornito
        informazioni personali, le elimino immediatamente
        dai nostri server. Se sei genitore o tutore e sei a conoscenza del fatto che
        tuo figlio ci ha fornito informazioni personali, ti prego di contattarmi in modo che
        possa intraprendere le azioni necessarie.
        </p></div> <!----> <p><strong>Modifiche a questa Politica sulla Privacy</strong></p> <p>
        Posso aggiornare la nostra Politica sulla Privacy di
        volta in volta. Pertanto, ti consiglio di rivedere questa pagina
        periodicamente per eventuali modifiche. Ti
        informerò di eventuali modifiche pubblicando la nuova Politica sulla Privacy su
        questa pagina.
    </p> <p>Questa politica è efficace dal 2024-02-22</p> <p><strong>Contattaci</strong></p> <p>
        Se hai domande o suggerimenti sulla mia
        Politica sulla Privacy, non esitare a contattarmi all'indirizzo email quizejogosonline@gmail.com.</p>
    </div>
</template>

<script>
export default {
    name: 'DiarioEmocionalPrivacyPolicy'
}
</script>