<template>
    <div>
        google.com, pub-4327755322079259, DIRECT, f08c47fec0942fa0
    </div>
</template>

<script>
export default {
    name: "AppAdsTxtScreen",
}
</script>