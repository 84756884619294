<template>
    <div>
        <strong>Algemene Voorwaarden</strong> <p>
            Door de app te downloaden of te gebruiken, zijn deze voorwaarden automatisch op u van toepassing - zorg er daarom voor dat u ze goed leest voordat u de app gebruikt. Het is niet toegestaan om de app, een deel van de app of onze handelsmerken op enige manier te kopiëren of te wijzigen. U mag niet proberen de broncode van de app te extraheren en u mag ook niet proberen de app naar andere talen te vertalen of afgeleide versies te maken. De app zelf, evenals alle handelsmerken, auteursrechten, databaserechten en andere intellectuele eigendomsrechten die ermee verband houden, blijven eigendom van Quiz & Jogos Online.
        </p> <p>
            Quiz & Jogos Online zet zich in om ervoor te zorgen dat de app zo nuttig en efficiënt mogelijk is. Om die reden behouden we ons het recht voor om wijzigingen aan te brengen in de app of kosten in rekening te brengen voor de diensten ervan, op elk moment en om welke reden dan ook. We zullen u nooit kosten in rekening brengen voor de app of de diensten ervan zonder u duidelijk te vertellen waarvoor u betaalt.
        </p> <p>
            De Emotioneel Eagboek-app slaat persoonlijke gegevens op en verwerkt deze die u ons hebt verstrekt, om mijn Service te leveren. Het is uw verantwoordelijkheid om uw telefoon en toegang tot de app veilig te houden. We raden u daarom aan om uw telefoon niet te jailbreaken of te rooten, wat het proces is om de softwarebeperkingen en -beperkingen opgelegd door het officiële besturingssysteem van uw apparaat te verwijderen. Dit kan uw telefoon kwetsbaar maken voor malware/virussen/kwaadaardige programma's, de beveiligingsfuncties van uw telefoon compromitteren en betekenen dat de Emotioneel Eagboek-app mogelijk niet goed werkt of helemaal niet werkt.
        </p> <div><p>
            De app maakt gebruik van diensten van derden die hun Algemene Voorwaarden verklaren.
            </p> <p>
            Link naar de Algemene Voorwaarden van derden die door de app gebruikte serviceproviders
            </p> <ul><li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play-services</a></li><li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google Analytics voor Firebase</a></li><li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/tos" target="_blank" rel="noopener noreferrer">One Signal</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
            U moet weten dat er bepaalde zaken zijn waarvoor Quiz & Jogos Online geen verantwoordelijkheid zal nemen. Bepaalde functies van de app vereisen dat de app een actieve internetverbinding heeft. De verbinding kan Wi-Fi zijn of worden geleverd door uw mobiele netwerkprovider, maar Quiz & Jogos Online kan niet verantwoordelijk worden gehouden als de app niet goed werkt als u geen toegang heeft tot Wi-Fi en als uw datalimiet op is.
        </p> <p></p> <p>
            Als u de app buiten een Wi-Fi-gebied gebruikt, moet u onthouden dat de voorwaarden van de overeenkomst met uw mobiele netwerkprovider nog steeds van toepassing zijn. Hierdoor kan uw mobiele provider u in rekening brengen voor de kosten van gegevens voor de duur van de verbinding tijdens het gebruik van de app, of andere kosten van derden. Door de app te gebruiken, aanvaardt u de verantwoordelijkheid voor dergelijke kosten, inclusief roamingkosten voor gegevens als u de app buiten uw thuisterrein (dwz regio of land) gebruikt zonder dataroaming uit te schakelen. Als u niet de betaler van de rekening bent voor het apparaat waarop u de app gebruikt, moet u er rekening mee houden dat wij ervan uitgaan dat u toestemming heeft gekregen van de betaler van de rekening voor het gebruik van de app.
        </p> <p>
            Op dezelfde manier kan Quiz & Jogos Online niet altijd verantwoordelijk worden gehouden voor de manier waarop u de app gebruikt, dat wil zeggen dat u ervoor moet zorgen dat uw apparaat is opgeladen: als de batterij leeg raakt en u deze niet kunt inschakelen om van de Service te profiteren, kan Quiz & Jogos Online geen verantwoordelijkheid aanvaarden.
        </p> <p>
            Wat betreft de verantwoordelijkheid van Quiz & Jogos Online voor uw gebruik van de app, is het bij het gebruik van de app belangrijk om in gedachten te houden dat hoewel we proberen ervoor te zorgen dat deze te allen tijde up-to-date en correct is, we afhankelijk zijn van derden om ons informatie te verstrekken zodat we deze aan u beschikbaar kunnen stellen. Quiz & Jogos Online aanvaardt geen aansprakelijkheid voor verlies, direct of indirect, dat u lijdt als gevolg van volledig te vertrouwen op deze functionaliteit van de app.
        </p> <p>
            Op een gegeven moment kunnen we de app willen bijwerken. Momenteel is de app beschikbaar op Android: de vereisten voor het systeem (en voor eventuele extra systemen waartoe we besluiten de beschikbaarheid van de app uit te breiden) kunnen veranderen en u moet de updates downloaden als u de app wilt blijven gebruiken. Quiz & Jogos Online belooft niet om de app altijd bij te werken zodat deze relevant is voor u en/of werkt met de Android-versie die u op uw apparaat hebt geïnstalleerd. U belooft echter altijd updates voor de applicatie te accepteren wanneer deze aan u worden aangeboden. We kunnen ook besluiten om te stoppen met het aanbieden van de app en kunnen het gebruik ervan op elk moment beëindigen zonder u hiervan op de hoogte te stellen. Tenzij we u anders vertellen, eindigen bij beëindiging van een dergelijke overeenkomst (a) de rechten en licenties die aan u zijn verleend in deze voorwaarden; (b) moet u stoppen met het gebruik van de app en, indien nodig, deze van uw apparaat verwijderen.
        </p> <p><strong>Wijzigingen in Deze Algemene Voorwaarden</strong></p> <p>
            Ik kan onze Algemene Voorwaarden van tijd tot tijd bijwerken. Daarom raad ik u aan deze pagina regelmatig te controleren op wijzigingen. Ik zal u op de hoogte stellen van eventuele wijzigingen door de nieuwe Algemene Voorwaarden op deze pagina te plaatsen.
        </p> <p>
            Deze algemene voorwaarden zijn van kracht vanaf 2024-02-22
        </p> <p><strong>Neem contact met ons op</strong></p> <p>
            Als u vragen of suggesties heeft over mijn Algemene Voorwaarden, aarzel dan niet om contact met mij op te nemen via quizejogosonline@gmail.com.
        </p>

    </div>
</template>

<script>
export default {
    name: "DiarioEmocionalTermsAndContions"
}
</script>