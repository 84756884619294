<template>
    <div>
        <strong>Política de Privacidade</strong> <p>
        Quiz & Jogos Online desenvolveu o aplicativo Diário Emocional como
        um aplicativo com Anúncios. Este SERVIÇO é fornecido por
        Quiz & Jogos Online gratuitamente e destina-se a ser utilizado como
        está.
    </p> <p>
        Esta página é usada para informar os visitantes sobre
        minhas políticas com a coleta, uso e divulgação de Informações Pessoais
        caso alguém decida utilizar meu Serviço.
    </p> <p>
        Se você optar por utilizar meu Serviço, então você concorda com
        a coleta e uso de informações em relação a esta
        política. As Informações Pessoais que eu recolho são
        utilizadas para fornecer e melhorar o Serviço. Eu não irei utilizar ou compartilhar suas informações com
        ninguém, exceto conforme descrito nesta Política de Privacidade.
    </p> <p>
        Os termos utilizados nesta Política de Privacidade têm os mesmos significados
        que em nossos Termos e Condições, acessíveis em
        Diário Emocional, a menos que definido de outra forma nesta Política de Privacidade.
    </p> <p><strong>Coleta e Uso de Informações</strong></p> <p>
        Para uma melhor experiência, ao utilizar nosso Serviço, eu
        posso exigir que você nos forneça certas informações de identificação pessoal, incluindo, mas não se limitando a nome, e-mail. As informações que
        solicito serão retidas no seu dispositivo e não são coletadas por mim de nenhuma forma.
    </p> <div><p>
        O aplicativo usa serviços de terceiros que podem coletar
        informações usadas para identificá-lo.
        </p> <p>
        Link para a política de privacidade dos prestadores de serviços de terceiros utilizados
        pelo aplicativo
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Serviços do Google Play</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics para Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li></ul></div> <p><strong>Dados de Registro</strong></p> <p>
        Eu quero informar que sempre que você
        usar meu Serviço, em caso de erro no aplicativo
        eu coleto dados e informações (através de produtos de terceiros)
        no seu telefone chamados Dados de Registro. Estes Dados de Registro podem
        incluir informações como o endereço IP do seu dispositivo, nome do dispositivo, versão do sistema operacional, a
        configuração do aplicativo ao utilizar meu Serviço,
        o horário e a data do uso do Serviço e outras estatísticas.
    </p> <p><strong>Cookies</strong></p> <p>
        Cookies são arquivos com uma pequena quantidade de dados que são
        comumente usados como identificadores exclusivos anônimos. Estes são enviados
        para o seu navegador a partir dos sites que você visita e são
        armazenados na memória interna do seu dispositivo.
    </p> <p>
        Este Serviço não utiliza esses “cookies” explicitamente. No entanto,
        o aplicativo pode usar código de terceiros e bibliotecas que usam
        “cookies” para coletar informações e melhorar seus serviços.
        Você tem a opção de aceitar ou recusar esses cookies
        e saber quando um cookie está sendo enviado para o seu dispositivo. Se você
        optar por recusar nossos cookies, você pode não ser capaz de usar algumas
        partes deste Serviço.
    </p> <p><strong>Prestadores de Serviços</strong></p> <p>
        Eu posso empregar empresas terceirizadas e
        indivíduos pelos seguintes motivos:
    </p> <ul><li>Para facilitar nosso Serviço;</li> <li>Para fornecer o Serviço em nosso nome;</li> <li>Para realizar serviços relacionados com o Serviço; ou</li> <li>Para nos ajudar a analisar como nosso Serviço é utilizado.</li></ul> <p>
        Eu quero informar aos usuários deste Serviço
        que esses terceiros têm acesso às suas Informações Pessoais. O motivo é executar as tarefas atribuídas a
        eles em nosso nome. No entanto, eles são obrigados a não
        divulgar ou usar as informações para qualquer outra finalidade.
    </p> <p><strong>Segurança</strong></p> <p>
        Eu valorizo a sua confiança ao nos fornecer suas
        Informações Pessoais, assim estamos nos esforçando para usar meios
        comercialmente aceitáveis de protegê-las. Mas lembre-se de que nenhum método
        de transmissão pela internet, ou método de armazenamento eletrônico é 100% seguro e confiável, e eu não posso
        garantir sua segurança absoluta.
    </p> <p><strong>Links para Outros Sites</strong></p> <p>
        Este Serviço pode conter links para outros sites. Se você clicar em
        um link de terceiros, você será direcionado para esse site. Observe
        que esses sites externos não são operados por mim.
        Portanto, eu recomendo fortemente que você reveja a
        Política de Privacidade desses sites. Eu
        não tenho controle e não assumo responsabilidade pelo conteúdo,
        políticas de privacidade ou práticas de sites ou serviços de terceiros.
    </p> <p><strong>Privacidade de Crianças</strong></p> <div><p>
        Estes Serviços não se destinam a menores de 13 anos. Eu não recolho
        conscientemente informações de identificação pessoal de crianças com menos de 13 anos. No caso
        de descobrir que uma criança menor de 13 anos nos forneceu
        informações pessoais, eu as excluo imediatamente
        dos nossos servidores. Se você é pai ou mãe ou responsável e está ciente de que
        seu filho nos forneceu informações pessoais, entre em contato comigo para que
        eu possa tomar as medidas necessárias.
        </p></div> <p><strong>Alterações a Esta Política de Privacidade</strong></p> <p>
        Eu posso atualizar nossa Política de Privacidade de
        tempos em tempos. Portanto, recomendamos que você revise esta página
        periodicamente para verificar se há alterações. Eu irei
        notificá-lo de quaisquer alterações postando a nova Política de Privacidade nesta página.
    </p> <p>Esta política é eficaz a partir de 2024-02-22</p> <p><strong>Entre em Contato</strong></p> <p>
        Se você tiver alguma dúvida ou sugestão sobre minha
        Política de Privacidade, não hesite em entrar em contato comigo pelo e-mail quizejogosonline@gmail.com.</p>
   </div>
</template>

<script>
export default {
    name: 'DiarioEmocionalPrivacyPolicy'
}
</script>